.about {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 100px;
  margin: 50px 0;
  @media screen and (max-width: 800px) {
    padding: 0 0;
  }
  // &_tab{
  //   &_container{}
  // }
  &_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 50px;
    margin: 0px 100px;
    @media screen and (max-width: 800px) {
      margin: 0 30px;
    }
    &_subtitle {
      color: #ababab;
      font-size: 14px;
      letter-spacing: 3px;
      margin-bottom: 50px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1.41;
      font-family: "ObjectiveBold";
      margin-top: 0;
    }
 
    &_title {
      color: $light;
      letter-spacing: 3px;
      text-transform: uppercase;
      line-height: 1.43;
      span {
        color: $theme-color--default;
      }
      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
    }
    &_desc {
      color: $light;
      line-height: 2;
      font-size: 16px;
      margin: 15px 0px;
      text-align: justify;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      .more_link{
        color: $theme-color--default;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
      @media screen and (max-width: 800px) {
       text-align: left;
       font-size: 14px;
      }
    }
    &_ceo {
      color: $light;
      line-height: 1.5;
      font-size: 16px;
      margin: 15px 0px;
      text-align: justify;
      display: flex;
      align-items: center;
      justify-content: space-around;
      & img{
        width: 150px;
        margin-right: 50px;
      }
      @media screen and (max-width: 599px) {
        flex-wrap: wrap;
       text-align: left;
      font-size: 14px;
      margin:  0px;

      & img{
        width: 150px;
        margin-right: 0;
        margin: 15px 0;
      }
      }
    }
    &_tasks {
      color: $light;
      font-size: 1.3rem;
      line-height: 2;
      margin-left: 25px;
      @media screen and (max-width: 800px) {
        margin-left: 0;
        font-size: 14px;
      }
      li {
        &:before {
          margin: 0 15px;
          color: $theme-color--default;
          content: "╞";
          @media screen and (max-width: 800px) {
            margin: 0 ;
            margin-right:10px ;
          }
        }
      }
   
    }
    // &_button {
    //   color: $light;
    //   background-color: $theme-color--default;
    //   border: 1px solid $theme-color--default;
    //   border-radius: 5px;
    //   font-size: 16px;
    //   padding: 15px;
    // }
  }
}
