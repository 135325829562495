.home-about {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 70px 0 0;
  @media screen and (max-width: 800px) {
    padding: 30px 0 0;

  }
  &_content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    @media screen and (max-width: 499px) {
      display:block;
  
    }
    // padding-top: 30px;
    &_subtitle {
      color: #ababab;
      font-size: 14px;
      letter-spacing: 3px;
      margin-bottom: 50px;
      text-transform: uppercase;
      @media screen and (max-width: 800px) {
        margin-bottom: 15px;
    
      }
    }
    &_title {
      flex: 1;
      letter-spacing: 3px;
      margin-left: 50px;
      text-transform: uppercase;
      line-height: 1.5;
      font-size: 60px;
      text-align: left;
      color: #202020;
      text-shadow: -4px -4px 0 #19969c, 1px -1px 0 #19969c, -1px 1px 0 #19969c,
        1px 1px 0 #19969c;
        @media screen and (max-width: 800px) {
          font-size: 35px;
          margin-left: 15px;
        }
    }
  }
  &_desc {
    color: $light;
    line-height: 2;
    font-size: 18px;
    flex: 2;
    margin: 0 50px;
    padding: 0 30px;
    text-align: justify;
    border-left: 3px solid $theme-color--default;
    span {
      color: $theme-color--default;
    }
    @media screen and (max-width: 800px) {
      font-size: 14px;
      border-left:none;
      line-height: 1.5;
      margin: 0 20px;
    padding: 0;
    text-align: left;
    }
    // &_button {
    //   color: $light;
    //   background-color:$theme-color--default;
    //   border:1px solid $theme-color--default;
    //   border-radius: 5px;
    //   font-size: 16px;
    //   padding:15px;
    // }
  }
  #slide {
    transition: 1s;
    left: 0;
  }

  #slide:hover {
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
  }

  @-webkit-keyframes slide {
    100% {
      left: 0;
    }
  }

  @keyframes slide {
    100% {
      left: 0;
    }
  }
}
