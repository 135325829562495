.assessment-finish {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 140px 50px 0;

  @media screen and (max-width: 1000px) {
    margin: 100px 0 0;
  }
  .result {
    // margin:0 0 50px;
    display: inline;
    font-size: 3vw;
    font-weight: 600;
    span {
      color: $theme-color--default;
    }
    @media screen and (max-width: 799px) {
      font-size: 22px;
      margin: 15px 0;
    }
  }
  .section-result-container {
    text-align: center;
    width: 50%;
    // display: flex;
    // justify-content: space-between;
    align-self: left;
    .section-result {
      padding: 30px 0;
      font-size: 16px;
      @media screen and (max-width: 799px) {
        padding: 15px;
      }
      .ant-progress-circle {
        .ant-progress-text {
          color: $light !important;
        }
      }
      h5 {
        margin: 15px 0;
      }
    }
  }
  .brand {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 25px;
    background-color: $theme-color--default;
    margin: 0;
    margin-left: -100px;
    @media screen and (max-width: 799px) {
      margin: 0;
    }
    // &_bg{
    //   width: 100%;
    //   height: 100%;
    //   background: url("../../images/bg/test-bg.png");
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 0;
    //   opacity: 0.6;
    // }
    p {
      font-size: 16px;
      font-weight: 600;
      @media screen and (max-width: 799px) {
        font-size: 14px;
      }
    }
    a {
      align-self: center;
      z-index: 999;

      border: 1px solid $theme-color--two;
      background-color: $theme-color--two;
      padding: 5px 15px;
      // border-radius: 2px;
      // margin-top: 20px;
      text-align: center;
      cursor: pointer;
      font-size: 20px;
      color: $light;
      &:hover {
        color: $light;
        background: $theme-color--two;
        border: 1px solid $theme-color--two;
        box-shadow: 0 2px 3px #222;
        transform: translateY(-2px);
      }
      @media screen and (max-width: 799px) {
        border: 1px solid $theme-color--two;
        // width: 100px;
        font-size: 16px;
        background-color: $theme-color--two;
        &:hover {
          background: $theme-color--two;
          border: 1px solid $theme-color--two;
        }
      }
    }
  }
}
.calendly-inline-widget {
  ._1Xg-U___styles-ConfirmButton__cls1 {
    background-color: red !important;
  }
}
