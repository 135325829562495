.service-call {
  background: url("../../images/patterns/mitech-home-appointment-call-to-action-bg-image.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  // flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // min-height: 100vh;
  margin: 50px 0;
  padding: 50px ;
  &_title {
    font-size: 3vw;
    text-align: left;
    width: 50%;
    span {
      color: $theme-color--default;
    }
  }
  &_content {
    display: flex;
  flex-wrap: wrap;

    justify-content: space-between;
    align-items: flex-start;
    margin: 0 100px;
    &_subtitle {
      font-size: 1rem;
      color: $theme-color--default;
      margin: 15px 0;
    }
    &_form {
      padding: 0px 25px;
      margin: 0px 25px;
      .ant-form {
        margin: 20px 0;
      }
      .ant-form-item {
        width: 400px;
      }
      input,
      textarea {
        border-radius: 5px;
        height: 50px;
        color: #202020;
        font-size: 14px;
        border-radius: 2px;
        &:hover {
          border: 1px solid $theme-color--default;
        }
        &:focus {
          border: 1px solid $theme-color--default;
        }
      } 
    }
    &_choices {
      flex: 2;
      border-left: 3px solid $theme-color--default;
      padding: 0px 25px;

      &_checkbox {
        .ant-checkbox-wrapper {
          color: $light;
          font-size: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .ant-checkbox-inner {
            border-color: $theme-color--default;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $theme-color--default;
            border-color: $theme-color--default;
          }
          .ant-checkbox-checked::after {
            border: none;
          }
          .ant-checkbox + span {
            padding-right: 8px;
            padding-left: 8px;
            padding-top: 8px;
          }
          .ant-checkbox-indeterminate {
            .ant-checkbox-inner {
              &:after {
                background-color: $theme-color--default;
              }
            }
          }
        }
        .ant-checkbox-group {
          padding-left: 25px;
        }
      }
      &_slider {
        margin: 28px 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .ant-slider {
          width: 100%;
          .ant-slider-mark-text {
            color: $light;
            font-size: 0.8rem;
          }
          .ant-slider-track {
            position: absolute;
            height: 4px;
            background-color: $theme-color--default;
            border-radius: 2px;
            -webkit-transition: background-color 0.3s;
            transition: background-color 0.3s;
          }
          .ant-slider-handle {
            position: absolute;
            width: 14px;
            height: 14px;
            margin-top: -5px;
            background-color: #fff;
            border: solid 2px $theme-color--default;
          }
          &:hover {
            .ant-slider-track {
              position: absolute;
              height: 4px;
              background-color: $theme-color--default;
              border-radius: 2px;
              -webkit-transition: background-color 0.3s;
              transition: background-color 0.3s;
            }
            .ant-slider-handle {
              position: absolute;
              width: 14px;
              height: 14px;
              margin-top: -5px;
              background-color: #fff;
              border: solid 2px $theme-color--default;
            }
          }
        }
        .ant-input-number {
          margin: 0 15px;
          min-width: 80px;
          max-width: 80px;
          padding: 0;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          border: 1px solid $theme-color--default;
          border-radius: 2px;
          .ant-input-number-handler-wrap {
            display: none;
          }
      
        }
      }
    }
    &_button {
      float: right;
      font-size: 2vw;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border: 1px solid $theme-color--default;
      background-color: $theme-color--default;
      padding: 5px 15px;
      border-radius: 5px;
      cursor: pointer;
      width: 18vw;
      height: 70px;
      i {
        margin-right: 15px;
      }
      &:hover {
        color: $light;
        background: $theme-color--default;
        border: 1px solid $theme-color--default;
        box-shadow: 0 2px 3px #222;
        transform: translateY(-2px);
      }
    }
  }
}
