.assessment-tools {
  color: $light;
  padding: 140px 50px;

  h2 {
    color: $theme-color--default;
    text-align: left;
  }
  .steps-content {
    padding: 50px;
    margin: 25px;
    color: $white;
    background-color: $light;
    border-radius: 15px;
    ol {
      font-size: 18px;
      line-height: 2;
      li {
        padding: 30px 0;
        p {
          margin-bottom: 30px;
        }
        .ant-slider-with-marks {
          margin-bottom: 40px;
        }
        .ant-slider:hover {
          .ant-slider-track {
            height: 8px;
            background-color: #e06d68;
          }
        }
        .ant-slider {
          width: 75%;

          .ant-slider-rail {
            height: 8px;
            background-color: #f5f5f5;
          }
          .ant-slider-track {
            height: 8px;
            background-color: $theme-color--default;
          }
          .ant-slider-step {
            height: 8px;
            .ant-slider-dot {
              top: -3px;
              width: 12px;
              height: 12px;
              background-color: #fff;
              border: 2px solid #f0f0f0;
            }
            .ant-slider-dot-active {
              background-color: $theme-color--default;
              border-color: #fff;
            }
          }
          .ant-slider-handle {
            width: 14px;
            height: 14px;
            margin-top: -4px;
            background-color: #fff;
            border: solid 2px $theme-color--default;
          }
          .ant-slider-mark {
            position: absolute;
            top: 20px;
            left: 0;
            width: 100%;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .steps-action {
    margin: 15px;
    text-align: right;
    .ant-btn {
      color: $theme-color--default;
      border: 1px solid $theme-color--default;
      border-radius: 5px;
    }
    .ant-btn-primary {
      color: $light;
      background-color: $theme-color--default;
    }
  }
  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: $theme-color--default;
      border-color: $theme-color--default;
      .ant-steps-icon {
        color: $light;
      }
    }

  }
  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: $theme-color--default;
      border-color: $theme-color--default;
      .ant-steps-icon {
        color: $light;
      }
    }
  }
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: transparent;
      border-color: $theme-color--default;
      .ant-steps-icon {
        color: $theme-color--default;
      }
    }
  }
  .ant-steps-item {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: $light;
        &:after {
          background-color: $theme-color--default;
        }
      }
    }
  }
}
// .assessment-tools{
//   max-width: 100%;
//   min-height: 80vh;
//   margin: 140px 60px;
//   position: relative;
//   // background-color: #fff;
//   @media screen and (max-width: 800px) {
//     margin: 100px 30px;
//   }
//   * {
//     font-size: 14px;
//   }
//   &_title {
//     color: #fff;
//     text-align: center;
//     h2 {
//       color: $theme-color--default;
//       font-size: 32px;
//     }
//   }
//   &_content {
//     margin: 30px 0;
//     .ant-steps-item-content{
//       padding: 0 0 50px;
//      .steps-content{
//         color: $light;
//         li {
//           padding: 10px 0;
//           p{
//             margin-bottom: 30px;
//           }
//           .ant-slider-with-marks {
//             margin-bottom: 40px;
//           }
//           .ant-slider:hover{
//             .ant-slider-track {
//               height: 8px;
//               background-color:#e06d68;
//             }
//           }
//           .ant-slider {
//             width: 75%;
//             @media screen and (max-width: 800px) {
//               width: 90%;
//             }
//             .ant-slider-rail {
//               height: 8px;
//               background-color: #f5f5f5;
//             }
//             .ant-slider-track {
//               height: 8px;
//               background-color: $theme-color--default;
//             }
//             .ant-slider-step {
//               height: 8px;
//               .ant-slider-dot {
//                 top: -3px;
//                 width: 12px;
//                 height: 12px;
//                 background-color: #fff;
//                 border: 2px solid #f0f0f0;
//               }
//               .ant-slider-dot-active {
//                 background-color: $theme-color--default;
//                 border-color: #fff;
//               }
//             }
//             .ant-slider-handle {
//               width: 14px;
//               height: 14px;
//               margin-top: -4px;
//               background-color: #fff;
//               border: solid 2px $theme-color--default;
//             }
//             .ant-slider-mark {
//               position: absolute;
//               top: 20px;
//               left: 0;
//               width: 100%;
//               font-size: 14px;
//               font-weight: 600;
//               .ant-slider-mark-text {
//                 color: $light;
//               }
//               .ant-slider-mark-text-active{
//                 color: $theme-color--default;
//               }
//             }
//           }
//         }
//       }
//     }
//     .show-step {
//       .ant-steps-item-description {
//         display: block !important;
//       }
//     }

//     .hide-step {
//       .ant-steps-item-description {
//         display: none !important;
//       }
//     }
//     .ant-steps-item-title {
//       min-height: 50px;
//       font-weight: 600;
//       margin-left: 15px;
//       padding-right: 0px;
//     }
//     .ant-steps {
//       .ant-steps-item-process {
//         .ant-steps-item-icon {
//           background-color: $theme-color--default;
//           border-color: $theme-color--default;

//           .ant-steps-icon {
//             color: #fff;
//           }
//         }

//         .ant-steps-item-title {
//           min-height: 50px;
//           color: $theme-color--default !important;
//         }

//         .ant-steps-item-container:hover .ant-steps-icon {
//           color: #fff !important;
//         }
//       }

//       .ant-steps-item-wait {
//         .ant-steps-item-icon {
//           background-color: transparent;
//           border-color: $theme-color--default;

//           .ant-steps-icon {
//             color: $theme-color--default;
//           }
//         }

//         .ant-steps-item-title {
//           min-height: 50px;
//           color: $light !important;
//         }

//         .ant-steps-item-container:hover .ant-steps-item-title {
//           color: $theme-color--default !important;
//         }
//       }

//       .ant-steps-item-error {
//         .ant-steps-item-icon {
//           background-color: #ff3b3b;
//           border-color: #ff3b3b !important;

//           .ant-steps-icon {
//             color: #fff !important;
//           }
//         }

//         .ant-steps-item-title {
//           min-height: 50px;
//           color: $light !important;
//         }

//         .ant-steps-item-container:hover .ant-steps-item-title {
//           color: #ff3b3b !important;
//         }
//       }

//       .ant-steps-item-finish {
//         .ant-steps-item-icon {
//           background-color: $theme-color--default;
//           border-color: $theme-color--default !important;

//           .ant-steps-icon {
//             color: #fff !important;
//           }
//         }

//         .ant-steps-item-title {
//           min-height: 50px;
//           color: $light !important;
//         }

//         .ant-steps-item-container:hover .ant-steps-item-title {
//           color: $theme-color--default !important;
//         }
//       }
//     }
//     .ant-steps-item-tail::after {
//       background-color: $theme-color--default !important;
//     }
//     .steps-action {
//       float: right;
//       .next-btn,
//       .next-btn:hover {
//         width: 100px;
//         height: 40px;
//         border-radius: 4px !important;
//         border: solid 1px $theme-color--default;
//         font-size: 18px !important;
//         background-color: $theme-color--default;
//         color: white;
//         margin: 25px 7px 0px;
//       }
//       .prev-btn,
//       .prev-btn:hover {
//         width: 150px;
//         height: 40px;
//         border-radius: 4px !important;
//         border: solid 1px $theme-color--default;
//         color: $theme-color--default;
//         margin: 25px 7px 0px;
//         font-size: 18px !important;
//         background-color: transparent !important;
//       }

//     }
//   }
// }
