/*=========================================
=               06. Footer Css            =
===========================================*/

.footer-area-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px;
  background-color: #2b2b2b;
  box-shadow: 2px -2px 3px #212121;
  @media screen and (max-width: 800px) {
  justify-content: center;
  }
//   border:3px solid #000;
.copyright-text{
  @media screen and (max-width: 800px) {
    text-align: center;
    }
}
  .logo-footer{
    text-align: center;
    img{
      width:150px;
      @media screen and (max-width: 800px) {
        width:100px;
        display: none;
      }
    }
  }
}
.social-media {
  display: flex;
  margin: 15px;
  .social-link {
    margin: 0px 10px;
    width:20px;
    .link-icon {
      font-size: 20px;
    }
    &:hover{
        .link-icon {
          color:$theme-color--default;
        }

    }
  }
}


/*=====  End of footer ======*/
