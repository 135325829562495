.home-team {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  padding:60px 0px;
  @media screen and (max-width: 800px) {
    padding: 50px 0;
  }

  &_content {
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    @media screen and (max-width: 800px) {
      padding: 0 30px;
    }
    &_subtitle {
      color: #ababab;
      font-size: 14px;
      letter-spacing: 3px;
      margin-bottom: 50px;
      text-transform: uppercase;
    }
    &_title {
      color: $light;
      letter-spacing: 3px;
      text-transform: uppercase;
      line-height: 1.43;
      span {
        color: $theme-color--default;
      }
    }
  }
  &_team-members {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding:50px 0 0;
 
    .ant-card {
      margin: 0;
      padding: 0;
      color: $light;
      background: transparent;
      border: none;
      text-align: center;
      width:250px;
      height: 350px;
      @media screen and (max-width: 800px) {
        margin: 25px 0;
      }
      &:hover {
        box-shadow: none;
      }
      .ant-card-cover img{
        height: 250px;
      }
      .ant-card-meta-detail {
        .ant-card-meta-title {
          color: $light;
          font-size: 20px;
        }
        .ant-card-meta-description {
          color: #ababab;
          font-size: 16px;
        }
      }

      .team-social-networks {
        font-size: 18px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 15px 25px;
        &_icon{
          width:50px;
        }
        &_icon:hover {
          // box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.16) ;
          color: $theme-color--default;
        }
      }
    }
  }
}
