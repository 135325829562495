::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: rgba(231, 76, 60, 0.8);
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(231, 76, 60, 0.4);
}
.test-card {
  margin: 50px 100px;
  @media screen and (max-width: 800px) {
    padding: 0;
    margin: 0;
  }
  &_subtitle {
    color: #ababab;
    font-size: 14px;
    letter-spacing: 3px;
    margin-bottom: 50px;
    text-transform: uppercase;
    @media screen and (max-width: 800px) {
      padding: 0 30px;
    }
  }

  &_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: hidden;
    overflow-x: scroll;
    @media screen and (max-width: 800px) {
      padding: 0;
    }

    .ant-card {
      border: none;
      background: url("../../images/bg/t.png");
      background-size: contain;
      background-repeat: no-repeat;
      height: 350px;
      text-align: center;
      padding: 25px 50px;
      margin: 25px 50px;
      border-radius: 15px;
      background-color: transparent;
      align-items: flex-start;
      color: $light;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      @media screen and (max-width: 499px) {
        text-align: left;
        font-size: 14px;
        margin: 30px 0;
        padding: 0;
        background-size: cover;
        height: 300px;
      }

      .ant-card-body {
        text-align: left;
        padding: 50px;
        width: 400px;
        @media screen and (max-width: 499px) {
          padding: 50px 60px;
        }
      }
      .ant-card-meta {
        padding: 20px;
        @media screen and (max-width: 499px) {
          padding: 0px;
        }
        .ant-card-meta-title {
          font-weight: bolder;
          color: $light;
        }
        .ant-card-meta-description {
          color: $theme-color--default;
        }
      }
    }
  }
}
