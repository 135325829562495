.service-bill {
  // background: url("../../images/patterns/mitech-home-appointment-call-to-action-bg-image.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // align-items: center;
  min-height: 100vh;
  margin: 170px 0;
  @media screen and (max-width: 800px) {
    margin: 100px 0;
  }
  &_title {
    font-size: 3vw;
    text-align: center;
    span {
      color: $theme-color--default;
    }
    @media screen and (max-width: 800px) {
      padding: 0;
      font-size: 20px;
    }
  }
  &_desc {
    font-size: 14px;
    text-align: center;
    margin: 15px 20%;
    letter-spacing: 2.5px;
    // padding: 0 150px;
    color: $theme-color-sub--heading;
    @media screen and (max-width: 800px) {
      margin: 15px;
      font-size: 12px;
      text-align: left;
    }
  }
  &_content {
    margin: 0 30px;
    @media screen and (max-width: 800px) {
      margin: 0 30px;
    }
   
    &_subtitle {
      font-size: 1rem;
      color: $theme-color--default;
      margin: 15px 0;
    }
    &_form {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: flex-start;
      @media screen and (max-width: 800px) {
        padding: 30px;
      }
      @media screen and (max-width: 499px) {
        padding: 30px 100px;
      }
      input,
      textarea {
        color: #202020;
        border: none;
        display: block;
        margin: 15px 0;
        width: 400px;
        @media screen and (max-width: 499px) {
          width: 250px;
        }
      }
      input {
        height: 40px;
      }
    }
    & form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding: 15px;

      @media screen and (max-width: 800px) {
        align-items: center;
      }
    }
    &_submit {
      background-color: $theme-color--default;
      float: right;
      border: none;
      width: 250px;
      height: 50px;
      color: $light;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        margin: 0 15px;
      }
      @media screen and (max-width: 800px) {
        width: 400px;
        float: none;
        i {
          margin: 0 15px;
        }
      }
      @media screen and (max-width: 499px) {
        width: 250px;
        float: none;
        i {
          margin: 0 15px;
        }
      }
    }
    &_choices {
      &_collapse {
        color: $light;
        justify-content: space-around;
        @media screen and (max-width: 800px) {
          justify-content: flex-start;
          align-items: flex-start;
        }
        &_service {
          // width: 265px;
          min-height: 500px;
          margin: 50px 0;

          &_title {
            text-align: center;
            font-size: 1.5rem;
            margin: 25px;
            color: $theme-color--default;
            font-weight: 600;
            cursor: pointer;
          }
          @media screen and (max-width: 800px) {
            // display: none;
            margin: 0;
            min-height: 100%;
            max-height: 500px;
            &_title {
              text-align: left;
              font-size: 22px;
              margin: 15px 0;
            }
          }
        }
        &_seperator {
          width: 1px;
          height: 400px;
          margin: 100px 0 0;
          background-color: $theme-color--default;
          align-self: flex-start;
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
        .ant-collapse {
          background-color: transparent;
          border: none;
          color: $light;
          .ant-collapse-header {
            font-size: 1rem;
            color: $light;
            .ant-collapse-arrow {
              color: $light;
              // transform: rotate(90deg);
            }
            &:hover {
              color: #ffa931;
              .ant-collapse-arrow {
                color: #ffa931;
              }
            }
          }

          .ant-collapse-item {
            border: none;

            .ant-collapse-content {
              background-color: transparent;
              border: none;

              padding-left: 25px;
            }
          }
        }
        .ant-checkbox-wrapper {
          color: $light;
          font-size: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0;
          .ant-checkbox {
            line-height: 0.5;
          }
          .ant-checkbox-inner {
            border-color: $light;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: $theme-color--default;
            border-color: $theme-color--default;
          }
          .ant-checkbox-checked::after {
            border: none;
          }
          .ant-checkbox + span {
            padding-right: 8px;
            padding-left: 8px;
            padding-top: 8px;
          }
          .ant-checkbox-indeterminate {
            .ant-checkbox-inner {
              &:after {
                background-color: $theme-color--default;
              }
            }
          }
        }
        .ant-checkbox-group {
          padding-left: 25px;
        }
      }
    }
    // &_button {
    //   float: right;
    //   font-size: 2vw;
    //   display: flex;
    //   justify-content: space-around;
    //   align-items: center;
    //   border: 1px solid $theme-color--default;
    //   background-color: $theme-color--default;
    //   padding: 5px 15px;
    //   border-radius: 5px;
    //   cursor: pointer;
    //   width: 18vw;
    //   i {
    //     margin-right: 15px;
    //   }
    //   &:hover {
    //     color: $light;
    //     background: $theme-color--default;
    //     border: 1px solid $theme-color--default;
    //     box-shadow: 0 2px 3px #222;
    //     transform: translateY(-2px);
    //   }
    // }
  }
 
}
.modal-error{
  // display: flex;
  // height: 250px;
  justify-content: center;
  position: relative;
  align-items: flex-start;
  // padding: 25px 0;
  p{
    font-size: 18px;
    font-weight: 600px;
    z-index: 2;
    color: $theme-color--black;
    margin: 0;
  }
  img{
    // right: -50px;
    // top: -35px;
    // position: absolute;
    width: 300px;
    z-index: 1;
  }
}
.ant-modal-confirm-btns{
  button{
    z-index: 0;
    background: $theme-color--default;
    border-color: $theme-color--default;
    &:hover{
      background: $theme-color--default;
      border-color: $theme-color--default;
    }
    &:focus{
      background: $theme-color--default;
      border-color: $theme-color--default;
    }
  }
}