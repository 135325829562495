// project specific variables

$theme-color--default: #e74c3c;
$theme-color--two: #6a2c70;
$theme-color--three: #19969C;
$theme-color--black: #202020;
$theme-color-sub--heading: #ffa931;
$transition--default: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

// Font Family

$font-family-name: "Objective";
$heading-font-family-name: "ObjectiveBold";
$font-awesome-pro: "ObjectiveMedium";
$font-awesome-brand: "Objectivelight";

// Colors ---------------

$white: #2b2b2b;
$black: #000000;
$light: #f8f9fa;


// Body Text
$body-text-color: #ffffff;
$body-font-weight: 400;
$body-line-height: 1.74;
$body-font-size: 15px;
$body-bg-color: $white;

// Heading Text
$heading-text-color: #ffffff;
$heading-font-weight: 700;
$heading-text-two: #ffffff;


// Responsive Variables
$xl-device: "only screen and (min-width: 1500px) and (max-width: 1599px)";
$xlmax-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1499px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";
