/*=============================================
=              05. Header Css            =
=============================================*/
.assessment-bar {
  background: rgba($color: #19969c, $alpha: 0.7);
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  z-index: 99;
  left: 0;
  padding: 10px 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light;
  p {
    margin: 0;
  }
  .assessment-btn {
    text-decoration: underline;
    font-weight: 600;
    color: $theme-color--default;
    margin: 0 5px;
  }
}
.randme {
  position: relative;
  top: 8px;
  width: 90px !important;
  opacity: 1;
  margin-left: 5px;
}
.ant-switch-checked,
.ant-switch {
  background-color: transparent;
  border: 1px solid $theme-color--three;
  margin: 0px 5px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 15px;
  height: 15px;
  &:before {
    background-color: $theme-color--three;
  }
}
// #logolink:hover #randme {
//   animation: slide 1000ms linear;
//   opacity: 1;
// }
// #logolink:hover #firstb {
//   animation: turning 1000ms linear;
// }
#logolink:hover #secondb {
  animation: turnback 1000ms linear;
}
@keyframes slide {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -50, 0, 0, 1);
  }
  3.2% {
    transform: matrix3d(
      1.133,
      0,
      0,
      0,
      0,
      1.133,
      0,
      0,
      0,
      0,
      1,
      0,
      -30.045,
      0,
      0,
      1
    );
  }
  4.3% {
    transform: matrix3d(
      1.152,
      0,
      0,
      0,
      0,
      1.152,
      0,
      0,
      0,
      0,
      1,
      0,
      -23.772,
      0,
      0,
      1
    );
  }
  6.41% {
    transform: matrix3d(
      1.165,
      0,
      0,
      0,
      0,
      1.165,
      0,
      0,
      0,
      0,
      1,
      0,
      -13.424,
      0,
      0,
      1
    );
  }
  8.61% {
    transform: matrix3d(
      1.155,
      0,
      0,
      0,
      0,
      1.155,
      0,
      0,
      0,
      0,
      1,
      0,
      -5.192,
      0,
      0,
      1
    );
  }
  12.71% {
    transform: matrix3d(
      1.106,
      0,
      0,
      0,
      0,
      1.106,
      0,
      0,
      0,
      0,
      1,
      0,
      3.434,
      0,
      0,
      1
    );
  }
  12.91% {
    transform: matrix3d(
      1.104,
      0,
      0,
      0,
      0,
      1.104,
      0,
      0,
      0,
      0,
      1,
      0,
      3.666,
      0,
      0,
      1
    );
  }
  17.22% {
    transform: matrix3d(
      1.051,
      0,
      0,
      0,
      0,
      1.051,
      0,
      0,
      0,
      0,
      1,
      0,
      5.76,
      0,
      0,
      1
    );
  }
  18.92% {
    transform: matrix3d(
      1.035,
      0,
      0,
      0,
      0,
      1.035,
      0,
      0,
      0,
      0,
      1,
      0,
      5.546,
      0,
      0,
      1
    );
  }
  25.23% {
    transform: matrix3d(
      0.999,
      0,
      0,
      0,
      0,
      0.999,
      0,
      0,
      0,
      0,
      1,
      0,
      3.152,
      0,
      0,
      1
    );
  }
  28.33% {
    transform: matrix3d(
      0.994,
      0,
      0,
      0,
      0,
      0.994,
      0,
      0,
      0,
      0,
      1,
      0,
      2.052,
      0,
      0,
      1
    );
  }
  31.43% {
    transform: matrix3d(
      0.992,
      0,
      0,
      0,
      0,
      0.992,
      0,
      0,
      0,
      0,
      1,
      0,
      1.295,
      0,
      0,
      1
    );
  }
  39.44% {
    transform: matrix3d(
      0.996,
      0,
      0,
      0,
      0,
      0.996,
      0,
      0,
      0,
      0,
      1,
      0,
      0.682,
      0,
      0,
      1
    );
  }
  56.46% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.007, 0, 0, 1);
  }
  61.66% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.021, 0, 0, 1);
  }
  81.48% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.999, 0, 0, 1);
  }
  83.98% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.999, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1, 0, 0, 1);
  }
}

@keyframes turning {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  3.6% {
    transform: matrix3d(
      -0.801,
      0.602,
      0,
      0,
      -0.684,
      -0.732,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  4.5% {
    transform: matrix3d(
      -1.001,
      0.033,
      0,
      0,
      -0.152,
      -0.99,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  7.21% {
    transform: matrix3d(
      0.024,
      -1.002,
      0,
      0,
      0.996,
      -0.11,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  9.01% {
    transform: matrix3d(
      0.773,
      -0.637,
      0,
      0,
      0.732,
      0.684,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  13.51% {
    transform: matrix3d(
      0.829,
      0.561,
      0,
      0,
      -0.483,
      0.877,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  17.92% {
    transform: matrix3d(
      0.624,
      0.782,
      0,
      0,
      -0.753,
      0.659,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  19.72% {
    transform: matrix3d(
      0.659,
      0.753,
      0,
      0,
      -0.732,
      0.681,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  26.03% {
    transform: matrix3d(
      0.918,
      0.397,
      0,
      0,
      -0.4,
      0.916,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  29.03% {
    transform: matrix3d(
      0.98,
      0.197,
      0,
      0,
      -0.206,
      0.979,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  32.23% {
    transform: matrix3d(
      0.999,
      0.034,
      0,
      0,
      -0.046,
      0.999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  34.63% {
    transform: matrix3d(
      0.999,
      -0.043,
      0,
      0,
      0.032,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  40.14% {
    transform: matrix3d(
      0.995,
      -0.1,
      0,
      0,
      0.094,
      0.996,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  44.74% {
    transform: matrix3d(
      0.997,
      -0.076,
      0,
      0,
      0.073,
      0.997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  57.26% {
    transform: matrix3d(
      1,
      0.006,
      0,
      0,
      -0.005,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  62.36% {
    transform: matrix3d(1, 0.011, 0, 0, -0.01, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  82.28% {
    transform: matrix3d(
      1,
      -0.001,
      0,
      0,
      0.001,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  84.68% {
    transform: matrix3d(
      1,
      -0.001,
      0,
      0,
      0.001,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@keyframes turnback {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  3.6% {
    transform: matrix3d(
      -0.47,
      -0.883,
      0,
      0,
      0.883,
      -0.47,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  7.21% {
    transform: matrix3d(
      -0.856,
      0.517,
      0,
      0,
      -0.517,
      -0.856,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  10.81% {
    transform: matrix3d(
      0.147,
      0.989,
      0,
      0,
      -0.989,
      0.147,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  14.31% {
    transform: matrix3d(
      0.791,
      0.611,
      0,
      0,
      -0.611,
      0.791,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  21.42% {
    transform: matrix3d(
      0.996,
      -0.084,
      0,
      0,
      0.084,
      0.996,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  28.53% {
    transform: matrix3d(
      0.973,
      -0.229,
      0,
      0,
      0.229,
      0.973,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  48.55% {
    transform: matrix3d(
      0.999,
      -0.04,
      0,
      0,
      0.04,
      0.999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  68.57% {
    transform: matrix3d(
      1,
      0.004,
      0,
      0,
      -0.004,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
.assessment-link {
  background-color: $theme-color--default;
  border: 1px solid $theme-color--default !important;
  border-radius: 10px;
  min-width: 120px;
  text-align: center;
  padding: 21px 10px !important;
  color: #fff;
  margin: 0px 5px;
  font-size: 1.05vw;
  font-weight: 500;
  line-height: 1.18;
  &:hover {
    color: #fff !important;
  }
}
.contact-link {
  border: 1px solid $theme-color--three !important;
  border-radius: 10px;
  // width: 120px;
  text-align: center;
  padding: 21px !important;
  color: #fff;
  margin: 0px 5px;
  font-size: 1.05vw;
  font-weight: 500;
  line-height: 1.18;
}
.firstb {
  width: 120px !important;
}

.header-area {
  z-index: 999;
  padding: 0 50px;
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100px;
  animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running
    fadeInDown;
  z-index: 999;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.2);

  // box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  // background-color: #2b2b2b;
}
.mobile {
  // ant-dropdown-menu-item
  a {
    color: $light;
  }
  .current_mobile {
    background-color: $theme-color--default;
  }
  &_brandwiser {
    a {
      color: $theme-color--default;
    }
  }
  &_assessment {
    a {
      background-color: $theme-color--three;
      color: $light;
      &:hover {
        color: $light;
      }
    }
  }
  &_contact {
    a {
      background-color: $theme-color--default;
      color: $light;
      &:hover {
        color: $light;
      }
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  &.default-menu-style {
    & .header__logo {
      flex-basis: 23.5%;
      padding: 14px 0;

      @media #{$laptop-device, $xl-device} {
        flex-basis: 13%;
      }
      @media #{$desktop-device,$tablet-device} {
        flex-basis: 40%;
        justify-content: flex-start;
      }
      @media #{$large-mobile} {
        flex-basis: 50%;
        justify-content: flex-start;
      }
    }

    & .header-right-box {
      flex-shrink: 0;
      flex-basis: 23.5%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media #{$laptop-device} {
        flex-basis: 23%;
      }
      @media #{$desktop-device} {
        flex-basis: 60%;
      }

      @media #{$tablet-device} {
        flex-basis: 60%;
      }
      @media #{$large-mobile} {
        flex-basis: 50%;
      }

      & .header-right-inner {
        display: flex;
        margin: 0 -12px;
        align-items: center;
        justify-content: flex-end;
        flex-shrink: 0;
        @media #{$large-mobile} {
          margin: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 1212px) {
  .header-area {
    height: 80px;
    padding: 0 15px;
    justify-content: space-between;
  }
  .firstb {
    width: 80px !important;
  }
  .ant-dropdown-menu {
    background-color: #202020;
  }
}
/*============================== 
    - Navigation Menu Css
===============================*/

.navigation-menu {
  display: flex;
  // justify-content: space-around;
  align-items: center;
  // flex: 2;
  & > ul {
    display: flex;
    & > li {
      margin: 0 5px;
      @media #{$xl-device} {
        margin: 0 22px;
      }
      @media #{$laptop-device} {
        margin: 0 5px;
      }
      & > a {
        display: block;
        color: #fff;
        padding: 21px 2px;
        position: relative;
        font-size: 1.05vw;
        font-weight: 500;
        line-height: 1.18;

        &:before {
          content: "";
          width: 0;
          height: 3px;
          bottom: 0;
          position: absolute;
          left: 0;
          background-color: $theme-color--default;
          transition: $transition--default;
          text-decoration: none;
        }
      }
      & > .current_link {
        &:after {
          color: $theme-color--default;
        }
        & span {
          color: $theme-color--default;
        }
        &:before {
          width: 100%;
          left: 0;
        }
      }
      &.has-children {
        & > a {
          position: relative;
          &:after {
            position: static;
            margin-left: 5px;
            font-family: $font-awesome-pro;
            content: "\f107";
            font-size: 14px;
            vertical-align: middle;
            transition: $transition--default;
          }
        }

        &--multilevel-submenu {
          position: relative;
          &:last-child {
            .submenu {
              @media #{$laptop-device} {
                right: 0;
                left: auto;
              }
            }
          }
        }

        &:hover {
          .megamenu {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
            &--home-variation {
              &__item {
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
              }
            }
          }
          & > .submenu {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &:hover,
      &.active {
        & > a {
          &:after {
            color: $theme-color--default;
          }
          & span {
            color: $theme-color--default;
          }
          &:before {
            width: 100%;
            left: 0;
          }
        }
      }
      & > .brandwiser_link {
        color: #e74c3c !important;
      }
    }
  }
  &.primary--menu {
    & > ul {
      & > li {
        & > a {
          padding: 31px 2px;
        }
      }
    }
  }

  &--text_white {
    & > ul {
      & > li {
        & > a {
          color: rgba(255, 255, 255, 0.7);
          &:before {
            background-color: $white;
          }
        }
        &.active {
          & > a {
            color: $white;
          }
        }

        &:hover,
        &.active {
          & > a {
            &:after {
              color: $white;
            }
            & > span {
              color: $white;
            }
          }
        }
      }
    }
  }
}

/*=====  End of Header  ======*/

// fadeInDown animation
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}
