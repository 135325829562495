.home-contact {
  // overflow: hidden;
  @media screen and (max-width: 800px) {
    margin: 0;
  }
  &_bg-image {
    display: flex;
    justify-content: center;
    padding: 50px 100px;
    align-items: flex-end;
    opacity: 0.9;
    margin: 0 -100px;
    background: url("../../images/bg/cta-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100vw;
    @media screen and (max-width: 800px) {
      margin: 0;
      flex-direction: column;
      padding: 25px;
      align-items: flex-start;
    }
  }
  &_desc {
    width: 50%;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
    &_subtitle {
      margin: 15px;
      margin-left: 0;
      font-size: 2.3vw;
      font-weight: bolder;
      letter-spacing: 3px;
      text-transform: uppercase;
      line-height: 1.43;
      color: $theme-color--default;
      @media screen and (max-width: 800px) {
        margin: 0 0 5px;
        font-size: 14px;
      }
      @media screen and (min-width: 599px)and(max-width: 800px) {
        margin-left: 120px;
        font-size: 12px;
      }
    }
    &_title {
      margin: 15px;
      margin-left: 0;
      font-size: 2vw;
      font-weight: bold;
      color: $light;
      letter-spacing: 3px;
      text-transform: uppercase;
      line-height: 1.43;
      @media screen and (max-width: 800px) {
        margin: 0;
        font-size: 12px;
      }
      @media screen and (min-width: 599px)and(max-width: 800px) {
        margin-left: 120px;
        font-size: 12px;
      }
    }
    &_call-to-action {
      font-size: 1.3vw;
      font-weight: 100;
      color: $light;
      text-transform: uppercase;
      text-align: center;
      @media screen and (max-width: 800px) {
        margin: 0;
        font-size: 12px;
      }
      @media screen and (min-width: 599px)and(max-width: 800px) {
        margin-left: 120px;
        font-size: 12px;
      }
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media screen and (max-width: 800px) {
      flex-direction: row;
      width: 100%;
    }
    .anticon {
      color: $light;
      font-size: 1.5rem;
      margin: 0 5px;
      padding: 0 5px;
      border-right: 1px solid $light;
    }
  
    &_number {
      font-size: 2.5vw;
      font-weight: 600;
      color: $theme-color--two;
      margin: 15px 0;
      &:hover {
        color: $theme-color--two;
        text-decoration: underline;
      }
    }
    &_link {
      font-weight: 400;
      text-align: center;
      border: 1px solid $theme-color--two;
      display: flex;
      justify-content: center;
      align-items: center;
      // flex-direction: column;
      font-size: 1.5vw;
      border-radius: 10px;
      color: $light;
      background: $theme-color--two;
      height: 7vh;
      min-width: 20vw;
      box-shadow: none;
      margin: 0 0 25px;
      &:hover {
        color: $light;
        text-decoration: none;
        background: $theme-color--two;
        border: 1px solid $theme-color--two;
        box-shadow: 0 5px 3px rgba(106, 44, 112,0.6);
        transform: translateY(-5px);
      }
      @media screen and (max-width: 800px) {
        margin: 10px 0;
        padding: 5px 0;
        font-size: 10px;
        height: 25px;
        width: 100px;
        border-radius: 0;
        &:hover {
          color: $light;
          text-decoration: none;
          background: $theme-color--two;
          border: 1px solid $theme-color--two;
          box-shadow: none;
          transform: none;
        }
      }
    }
    .whatsup{
      background: rgb(49, 185, 70);
      border: 1px solid #31ba46;
      &:hover{
        box-shadow: 0 5px 3px rgba(49, 185, 70,0.6);
      }
    }
  }
}
