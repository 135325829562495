.build-me {
  width: 100%;
  margin-top: 140px;
  @media screen and (max-width: 800px) {
    margin-top: 100px;
  }
  &_header {
    width: 100%;
    min-height: 400px;
    background-size:100%;
    background-image: url("../../images/bg/build-me.png");
    background-repeat: no-repeat;
    @media screen and (max-width: 699px) {
      background-size: 100%;
      min-height: 100px;
    }
  }
  &_content {
    padding: 50px 100px;
    @media screen and (max-width: 800px) {
      padding: 30px;
    }
    &_title {
      font-size: 2rem;
      color: $light;
      font-weight: 600;
      span {
        color: $theme-color--default;
      }
      @media screen and (max-width: 800px) {
        font-size: 20px;
      }
    }
    &_desc {
      padding: 15px;
      margin:20px 50px 0px 150px;
      border-left: 2px solid $theme-color--default;
      p {
        font-size: 1.2rem;
        color: $light;
        text-align: justify;
        span {
          color: $theme-color--default;
        }
      }
      @media screen and (max-width: 800px) {
        padding: 0 0 0 15px;
        margin: 15px 0;
        p {
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }
}
