.home-services {
  min-height: 80vh;
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  padding:100px 100px 0;
  @media screen and (max-width: 800px) {
    padding: 30px 30px 0;

  }
  @media screen and (max-width: 799px) {
    display:block;
  }
  &_content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  
    &_subtitle {
      color: #ababab;
      font-size: 14px;
      letter-spacing: 3px;
      margin-bottom: 50px;
      text-transform: uppercase;
    }
    &_title {
      color: $light;
      letter-spacing: 3px;
      text-transform: uppercase;
      line-height: 1.43;
      span{
        color:$theme-color--default;
      }
      // @media screen and (max-width: 499px) {
      //   height: 40vh;
      // }
    }
  }
  &_card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:50px 0;
    .ant-card-bordered {
      border: 2px solid $theme-color--default;
      border-radius: 15px;
      position: relative;
    }
    .ant-card {
      height: 400px;
      width: 500px;
      color: $light;
      background: transparent;
      .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .arrow-icon {
          position: absolute;
          bottom:-25px;
          border: 1px solid $theme-color--default;
          background-color:$theme-color--default;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          .anticon{
            color: $light;
            font-size:20px;
            font-weight: 600;
          }
        }
      }
    }
    &_icon{
      // width: 70%;
      margin:15px 0px;
      img{
        object-fit: contain;
        width: 100%;
        height: 100px;
      }
    }
    &_title{
      font-size:1.5rem;
      margin:15px 0px;
    }
    &_desc{
      font-size:1rem;
      margin:15px 0px;
    }
  }
  &_video{
    width:100%;
    height: 60vh;
    margin: 50px 0 0;
    @media screen and (max-width: 499px) {
      margin: 25px 0 0;
      height: 40vh;
    }
  }
}
