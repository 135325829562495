.tenfree {
  width: 100%;
  margin-top: 140px;
  @media screen and (max-width: 800px) {
    margin-top: 100px;
  }
  &_header {
    background-image: url("../../images/bg/contact.png");
    width: 100%;
    min-height: 400px;
    background-size: 100%;
    background-position:  0 -60px ;
    background-repeat: no-repeat;
    @media screen and (max-width: 699px) {
      display: none;
    }
  }
  &_content {
    padding: 50px 100px;
    @media screen and (max-width: 800px) {
      padding: 0 30px;
    }
    &_title {
      border-left: 3px solid $theme-color--default;
      padding: 15px;
      font-size: 2rem;
      color: $light;
      font-weight: 600;
      span {
        color: $theme-color--default;
      }
      @media screen and (max-width: 800px) {
        border: none;
        font-size: 18px;
        padding: 0;
      }
    }
    &_form-container {
      padding: 15px;
      @media screen and (max-width: 499px) {
        padding: 15px 0;
        width: 250px;
      }
      &_form {
        width: 100%;

        input,
        textarea {
          color: #202020;
          border: none;
          display: block;
          margin: 15px 0;
          width: 100%;
          padding: 0 5px;
          @media screen and (max-width: 499px) {
            width: 100%;
          }
        }
        input {
          height: 40px;
        }
        button {
          background-color: $theme-color--default;
          border: none;
          width: 100%;
          height: 50px;
          color: $light;
        }
      }
    }
  }
}
