.assessment-start {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: $light;
  padding: 50px;
  background: url("../../images/bg/assessment_start.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  @media screen and (max-width: 799px) {
    padding: 30px;
    background: url("../../images/bg/assessment_start_empty.jpg");
    background-size: fill;
    background-position: 100% 30%;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  h2 {
    color: $theme-color--default;
    text-align: center;
    font-size: 35px;
    padding-top: 80px;
    @media screen and (max-width: 799px) {
      font-size: 30px;
    }
  }
  .mail {
    width: 350px;
    margin-top: 200px;
    // margin-bottom:50px;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    min-height: 200px;
    @media screen and (max-width: 799px) {
      font-size: 30px;
      justify-content: center;
      align-items: center;
      // text-align: left;
      width: 100%;
      margin-top: 20px;
      // input {
      //   width: 250px;
      // }
      
    }
    p {
      color: $light;
      text-align: left;
      font-size: 1rem;
      span {
        color: $theme-color--default;
      }
      @media screen and (max-width: 799px) {
        font-size: 14px;
      }
    }
    .subs {
      color: $light;
      text-align: left;
      font-size: 12px;
      margin: 10px 0;
      span {
        color: $theme-color--default;
      }
    }
  }

  .ant-btn {
    font-size: 1.8vw;
    align-self: center;
    border: 1px solid $theme-color--default;
    background-color: $theme-color--default;
    padding: 5px 15px;
    // border-radius: 2px;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    width: 16vw;
    height: 50px;
    color: $light;
    &:hover {
      color: $light;
      background: $theme-color--default;
      border: 1px solid $theme-color--default;
      box-shadow: 0 2px 3px #222;
      transform: translateY(-2px);
    }
    @media screen and (max-width: 799px) {
      border: 1px solid $theme-color--three;
      width: 100px;
      font-size: 16px;
      background-color: $theme-color--three;
      &:hover {
        background: $theme-color--three;
        border: 1px solid $theme-color--three;
      }
    }
  }
  .ant-btn[disabled] {
    font-size: 1.8vw;
    align-self: center;
    border: 1px solid $theme-color--default;
    background-color: $theme-color--default;
    padding: 5px 15px;
    // border-radius: 2px;
    margin-top: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    width: 16vw;
    height: 50px;
    @media screen and (max-width: 799px) {
      border: 1px solid $theme-color--three;
      width: 100px;
      font-size: 16px;
      background-color: $theme-color--three;
    }
  }
}
