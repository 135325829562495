.infotechno-video-bg {
  // height: 100vh !important;
  // position: relative;
  .embed-container {
    --video--width: 1920;
    --video--height: 1080;
  
    position: relative;
    padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
    overflow: hidden;
    max-width: 100%;
    background: black;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &_play{
    // position: absolute;
    // top: 0;
    // right: 0;
    // width: 100%;
    // height: calc(100%+20vh);
    // z-index: 99;
    // background-color: rgba(0,0,0,0.2);
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }


}
