.services-details {
    margin: 50px 0;
    @media screen and (max-width: 800px) {
      margin: 0;
    }
    &_title {
      font-size: 2rem;
      color: $light;
      font-weight: 600;
      span {
        color: $theme-color--default;
      }
    }
    &_list {
      margin: 50px;
      @media screen and (max-width: 1000px) {
        margin: 30px 0;
      }
      .ant-collapse {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        background-color: transparent;
        border: none;
        border-radius: 2px;
        width: 100%;
        .ant-collapse-item {
          border-bottom: none;
          .ant-collapse-header {
            @media screen and (max-width: 1000px) {
              font-size: 12px;
              padding: 0;
              margin: 0;
              }
            .ant-collapse-arrow {
              color: #d2a98e;
              font-style: normal;
              line-height: 0;
              text-align: right;
              text-transform: none;
              position: absolute;
              top: 50%;
              right: 0;
              display: inline-block;
              font-size: 1rem;
              padding: 0px 5px;
              // transform: rotate(90deg);
            }
          }
          .ant-collapse-content {
            overflow: hidden;
            color: #d2a98e;
            background-color: transparent;
            border-top: none;
            padding-left: 50px;
            line-height: 1.7;
            font-size: 1rem;
            @media screen and (max-width: 800px) {
            font-size: 16px;
            padding: 0;
            }
            li {
              margin-bottom: -7px;
              &:before {
                margin: 0 15px;
                color: #e74c3c;
                content: "╞";
              }
            }
          }
        }
      }
      &_item {
        display: flex;
        align-items: center;
        margin: 15px;
        i {
          font-size: 2.5rem;
          color: #d2a98e;
          @media screen and (max-width: 800px) {
            font-size: 24px;
          }
        }
        span {
          font-size: 1.8rem;
          @media screen and (max-width: 800px) {
            font-size: 20px;
          }
          padding: 0 15px;
          color: $theme-color--default;
          font-weight: 600;
        }
      }
    }
  }